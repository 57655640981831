<template>
<!--  <page-header-wrapper>-->
    <a-modal :visible="visible"
             width="70%"
             :footer="null"
             @cancel="cancel">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户名 " prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户名 " allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="手机号" prop="userPhone">
                <a-input v-model="queryParam.userPhone" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="订单号" prop="orderNo">
                  <a-input v-model="queryParam.orderNo" placeholder="请输入订单号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="变动类型" prop="disType">
                  <a-select v-model="queryParam.disType" placeholder="请选择变动类型" allow-clear>
                    <a-select-option :key="0">金币充值</a-select-option>
                    <a-select-option :key="1">购买会员</a-select-option>
                    <a-select-option :key="2">购买商品</a-select-option>
                    <a-select-option :key="3">月结扣除</a-select-option>
                    <a-select-option :key="4">手动扣除</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="消费平台" prop="payType">
                  <a-select v-model="queryParam.payType" placeholder="请选择消费平台" allow-clear>
                    <a-select-option :key="0">IOS</a-select-option>
                    <a-select-option :key="1">PayPal</a-select-option>
                    <a-select-option :key="2">ShopiFy</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['banner:banner:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['banner:banner:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['banner:banner:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['banner:banner:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

<!--        <img slot="bannerUsl" slot-scope="text, record" style="width: 48px;height: 48px;"  v-image-preview :src="record.bannerUsl">-->
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.bannerUsl" slot="bannerUsl" slot-scope="text, record">
<!--        <video  style="width: 200px;"  v-if="record.videoUrl"  :src="record.videoUrl"  slot="videoUrl" slot-scope="text, record"  controls></video>-->
<!--        <video  style="width: 200px;"  v-else poster="./modules/novideo.png"  :src="record.videoUrl" slot="videoUrl" slot-scope="text, record"  controls></video>-->

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['banner:banner:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['banner:banner:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['banner:banner:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['banner:banner:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :style="{ marginRight: '0' }"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => accountBillChild + 1"
      />
    </a-modal>
<!--  </page-header-wrapper>-->
</template>

<script>
import { getChildDisBill,listBanner, delBanner } from '@/api/banner/banner'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'


export default {
  name: 'accountBillChild',
  components: {
    // CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      visible: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        payType: null,
        nickname: null,
        userPhone: null,
        userId: null,
        orderNo: null,
        disType: null,
        videoId: null,
        isJump: null,
        bannerUsl: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '用户名',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'userPhone',
          // ellipsis: true,,
          align: 'center'
        },
        // {
        //   title: '短剧id',
        //   dataIndex: 'videoId',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '分销商级别',
          dataIndex: 'type',
          // ellipsis: true,,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '一级分销商'
            }else if(t == 1) {
              return '二级分销商'
            }
          }
        },
        {
          title: '上级分销商',
          dataIndex: 'realName',
          // ellipsis: true,,
          align: 'center',
        },
        {
          title: '订单号',
          dataIndex: 'orderNo',
          // ellipsis: true,,
          width: '20%',
          align: 'center',
        },
        {
          title: '订单金额',
          dataIndex: 'orderMoney',
          // ellipsis: true,,
          align: 'center',
        },
        {
          title: '变动类型',
          dataIndex: 'disType',
          // ellipsis: true,,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '金币充值'
            }else if(t == 1) {
              return '购买会员'
            }else if(t == 2) {
              return '购买商品'
            }else if(t == 3) {
              return '月结扣除'
            }else if(t == 4) {
              return '手动扣除'
            }
          }
        },
        {
          title: '消费平台',
          dataIndex: 'payType',
          // ellipsis: true,,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return 'IOS'
            }else if(t == 1) {
              return 'PayPal'
            }else if(t == 2) {
              return 'ShopiFy'
            }
          }
        },
        {
          title: ' 变动前余额',
          dataIndex: 'beforeMoney',
          // ellipsis: true,,
          align: 'center',
        },
        {
          title: '变动金额',
          dataIndex: 'changeMoney',
          // ellipsis: true,,
          align: 'center',
        },
        {
          title: '变动后余额',
          dataIndex: 'afterMoney',
          // ellipsis: true,,
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          // ellipsis: true,,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   ellipsis: true,
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  // created () {
  //   this.getList()
  // },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询轮播图列表 */
    getList () {
      this.loading = true
      this.queryParam.userId=this.userId
     getChildDisBill(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    getDatas(record) {
      this.userId = record
      this.visible = true
      this.getList()
    },

    cancel(){
      this.userId = null
      this.visible=false
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sort: undefined,
        videoId: undefined,
        isJump: undefined,
        bannerUsl: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.queryParam.pageNum = 1
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        //content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBanner(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('banner/banner/export', {
            ...that.queryParam
          }, `轮播图_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
